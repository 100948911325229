<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <form action="#" v-if="vmProductInviteFormData && Object.keys(vmProductInviteFormData).length > 0">
              <div class="row w-100 ml-0">
                <div class="col-12 col-sm-5 col-md-4 col-lg-4 mb-3" v-if="!propProductCode">
                  <label for="inviteProductCode">{{ cvProductCode }}</label>&nbsp;<label class="primary-color font-size-16">*</label>
                  <ValidationProvider name="Product Name / Code" rules="required|min:1" v-slot="{ errors }">
                    <select id="productCode" v-model="inviteProductCode" class="form-control" @change="updateProduct(inviteProductCode)">
                      <option disabled value="">Please Select</option>
                      <option v-for="(products, index) in GideProducts" :value="products" :key="index">
                        {{ products == "spcm" ? "Counseling" : (products == "IEC" ? "GIDEPreneur" : (products == "gideplus" ? "Student Club" : products)) }} [{{ products }}]</option>
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <ValidationObserver ref="inviteUserForm" class="row w-100 m-0">
                <div class="row w-100 ml-0">
                  <div class="col-12 col-sm-5 col-md-4 col-lg-4 mb-3">
                    <label for="validationslot_student_name">{{ cvStudentNameLabel }}</label>&nbsp;<label class="primary-color font-size-16">*</label>
                    <ValidationProvider name="Student Name" rules="required|min:2" v-slot="{ errors }">
                      <input v-model="vmProductInviteFormData.student_name" type="text" class="form-control" required/>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <!-- student class -->
                  <div class="col-12 col-sm-4 col-md-4 col-lg-2 mb-3">
                    <label for="studentClass">{{ cvStudentClass }}</label>&nbsp;<label class="primary-color font-size-16">*</label>
                    <ValidationProvider name="Student Class" rules="required|min:1" v-slot="{ errors }">
                      <select id="studentClass" v-model="vmProductInviteFormData.stu_class" class="form-control">
                        <option disabled value="">Please Select Class</option>
                        <option v-for="(classNo, key) in ClassJson" :value="classNo" :key="key">{{ classNo }}</option>
                      </select>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider><!-- student class -->
                  </div>
                </div>

                <div class="row w-100 ml-0">
                  <!-- student email -->
                  <div class="col-12 col-sm-4 col-md-4 col-lg-4 mb-3">
                    <label for="validationslot_student_email">{{ cvStudentEmailLabel }}</label>&nbsp;<label class="primary-color font-size-16">*</label>
                    <ValidationProvider name="Student Email" rules="required|min:2" v-slot="{ errors }">
                      <input v-if="propStudent && propStudent.student_email" v-model="vmProductInviteFormData.stu_email" readonly type="text" class="form-control" required />
                      <input v-else v-model="vmProductInviteFormData.stu_email" type="text" class="form-control" required />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div><!-- student email -->

                  <!-- Student Mobile Country Code -->
                  <div class="col-12 col-sm-4 col-md-3 col-lg-2 mb-3">
                    <label for="validationbill_country">Country Code</label>&nbsp;<label class="primary-color font-size-16">*</label>
                    <ValidationProvider name="Student Mobile Country Code" rules="required|min:2" v-slot="{ errors }">
                      <select v-model="vmProductInviteFormData.stu_mobile_country_code" class="form-control w-100" id="validation_country">
                        <template class="w-100">
                          <option class="w-100" v-for="(country, index) of cvCountryOptions" :key="index+'1'" :value="`+${country.country_phone_code}`">
                            {{country.country_name}} (+ {{country.country_phone_code}})
                          </option>
                        </template>
                      </select>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div><!-- Student Mobile Country Code -->

                  <!-- student Mobile -->
                  <div class="col-12 col-sm-4 col-md-4 col-lg-4 mb-3">
                    <label for="validationslot_student_mobile">{{ cvStudentMobileLabel }}</label>&nbsp;<label class="primary-color font-size-16">*</label>
                    <ValidationProvider name="Student Mobile Number" rules="required|min:3|max:15" v-slot="{ errors }">
                      <input v-if="propStudent && propStudent.student_mobile" v-model="vmProductInviteFormData.stu_mobile" readonly type="tel" class="form-control" required />
                      <input v-else v-model="vmProductInviteFormData.stu_mobile" type="number" class="form-control" required />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div><!-- student Mobile -->
                </div>

                <!-- parent Name -->
                <div class="row w-100 ml-0">
                  <div class="col-12 col-sm-4 col-md-4 col-lg-4 mb-3">
                    <label for="validationslot_parent_name">{{ cvParentNameLabel }}</label>&nbsp;<label class="primary-color font-size-16">*</label>
                    <ValidationProvider name="Parent Name" rules="required|min:2" v-slot="{ errors }">
                      <input v-model="vmProductInviteFormData.parent_name" type="text" class="form-control" required/>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div><!-- parent Name -->

                <div class="row w-100 ml-0">
                  <!-- Parent/other email -->
                  <div class="col-12 col-sm-5 col-md-4 col-lg-4 mb-3">
                    <label for="validationslot_other_email">{{ cvOtherEmailLabel }}</label>&nbsp;<label class="primary-color font-size-16">*</label>
                    <ValidationProvider name="Parent/Other Email" rules="required|min:2" v-slot="{ errors }">
                        <input v-if="propStudent && propStudent.other_email" v-model="vmProductInviteFormData.other_email" readonly type="text" class="form-control" required />
                        <input v-else v-model="vmProductInviteFormData.other_email" type="text" class="form-control" required />
                        <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div><!-- Parent/other email -->

                  <!-- Parent/Other Mobile Country Code -->
                  <div class="col-12 col-sm-3 col-md-3 col-lg-2 mb-3">
                    <label for="validationbill_country">Country Code</label>&nbsp;<label class="primary-color font-size-16">*</label>
                    <ValidationProvider name="Parent/Other Mobile Country Code" rules="required|min:2" v-slot="{ errors }">
                      <select v-model="vmProductInviteFormData.other_mobile_country_code" class="form-control w-100" id="validation_country">
                        <template class="w-100">
                          <option class="w-100" v-for="(country, index) of cvCountryOptions" :key="index+'1'" :value="`+${country.country_phone_code}`">
                            {{country.country_name}} (+ {{country.country_phone_code}})
                          </option>
                        </template>
                      </select>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div><!-- Parent/Other Mobile Country Code -->

                  <!-- Parent/other Mobile -->
                  <div class="col-12 col-sm-4 col-md-4 col-lg-4 mb-3">
                    <label for="validationslot_other_email">{{ cvOtherMobileLabel }}</label>&nbsp;<label class="primary-color font-size-16">*</label>
                    <ValidationProvider name="Parent/Other Mobile" rules="required|min:2" v-slot="{ errors }">
                      <input v-if="propStudent && propStudent.other_mobile" v-model="vmProductInviteFormData.other_mobile" readonly type="text" class="form-control" required />
                      <input v-else v-model="vmProductInviteFormData.other_mobile" type="text" class="form-control" required />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div><!-- Parent/other Mobile -->
                </div>

                <!-- IS SPCM -->
                <div class="row w-100 ml-0" v-if="inviteProductCode == 'spcm'">
                  <!-- SPCM Category -->
                  <div class="col-12 col-sm-4 col-md-4 col-lg-4 mb-3">
                    <label for="spcmcategory">{{ cvSpcmCategoryLabel }}</label>&nbsp;
                    <select id="spcmcategory" v-model="vmProductInviteFormData.spcm_category" class="form-control">
                      <option disabled value="">Select Counseling Type</option>
                      <option v-for="(category, key) in SpcmCategory" :value="category" :key="key">{{ category }}</option>
                    </select>
                  </div><!-- SPCM Category -->
                </div>

                <div class="row w-100 ml-0" v-if="inviteProductCode == 'spcm'">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                    <label for="">{{ cvSelecteCountryStrategy }}</label>
                    <b-form-checkbox-group
                      id="checkbox-group-1"
                      v-model="selected_ms_country_strategy"
                      :options="country_strategy_list"
                      name="flavour-1"
                      class="mt-2"
                      ></b-form-checkbox-group>
                  </div>
                </div><!-- IS SPCM -->

                <div class="row w-100 ml-0">
                  <!-- Subscription Plan Dropdown -->
                  <div class="col-12 col-sm-4 col-md-4 col-lg-4 mb-3" v-if="(userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118')">
                    <label for="validationslot_subscription_plan">{{ cvSubscriptionPlan }}</label>&nbsp;<label class="primary-color font-size-16">*</label>
                    <ValidationProvider name="Subscription Plan" rules="required|min:2" v-slot="{ errors }">
                      <select v-model="vmProductInviteFormData.subs_plan_id" class="form-control">
                        <option disabled value="">Select Payment Plan</option>
                        <option v-for="item in planIdList" :value="item.subs_plan_id" :key="item.subs_plan_id">{{ item.subs_plan_name }}&nbsp;-&nbsp;{{item.subs_plan_price}}</option>
                      </select>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div><!-- Subscription Plan Dropdown -->

                  <div class="row w-100 ml-0">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                      <button type="button" class="btn btn-primary" :disabled="disableGetCode" @click="generateAffLink()">{{ cvGetCodeBtn }}</button>&nbsp;&nbsp;:&nbsp;&nbsp;
                      <span v-if="vmProductInviteFormData.aff_code" style="vertical-align: middle; color: #FFF; background: #e5252c !important; font-size: 18px;">
                        {{vmProductInviteFormData.aff_code}}
                      </span>
                    </div>
                  </div>
                </div>
              </ValidationObserver>
            </form>

            <p>
              <button v-if="vmProductInviteFormData.attach_aff_code" type="button" class="btn btn-primary ml-1 mt-1" @click="inviteUser()" :disabled="!disableGetCode && vmProductInviteFormData.attach_aff_code">{{ cvSendEmailBtn }}</button>
              <button v-else type="button" class="btn btn-primary ml-1 mt-1" disabled>{{ cvSendEmailBtn }}</button>
            </p>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { ValidationProvider } from "vee-validate"
import { Affiliates } from "../FackApi/api/Affiliate"
import { Spcms } from "../FackApi/api/Spcm"
import ApiResponse from "../Utils/apiResponse"
import { GPathSessions } from "../FackApi/api/GPathSession"
import { TprepSessions } from "../FackApi/api/TprepSession"
import { SubscriptionPlans } from "../FackApi/api/Subscription/subscriptionPlan"
import GideProducts from "../FackApi/json/GideProducts.json"
import TestSeriesConfigJson from "../FackApi/json/TestSeries.json"
import SpcmCategory from "../FackApi/json/SpcmCategory.json"
import ClassJson from "../FackApi/json/Class.json"
import { spcmMilestones } from "../FackApi/api/Spcmmilestones"
import { Countries } from "../FackApi/api/country.js"
import { socialvue } from "../config/pluginInit"
import userPermission from "../Utils/user_permission.js"

export default {
  name: "InviteStudent",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propCounsellor: {
      type: Object,
      default: function () {
        return {
          user_name: ""
        }
      }
    },
    propStudent: {
      type: Object,
      default: null
    },
    propProductCode: {
      type: String,
      default: null
    },
    propProductName: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      apiName: "invite",
      TestSeriesConfigJson: TestSeriesConfigJson,
      SpcmCategory: SpcmCategory,
      ClassJson: ClassJson,
      cvCardTitle: "Invite Student",
      cvGetCodeBtn: `Get Discount / Payment Code`,
      cvSendEmailBtn: "Invite",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Invite User",
      vmProductInviteFormData: Object.assign({}, this.initFormData()),
      cvProductCode: "Product Name / Code",
      cvStudentEmailLabel: "Student Email",
      cvStudentMobileLabel: "Student Mobile",
      cvOtherEmailLabel: "Parent/Other Email",
      cvOtherMobileLabel: "Parent/Other Mobile",
      cvStudentClass: "Student Class",
      cvStudentNameLabel: "Student Name",
      cvParentNameLabel: "Parent Name",
      affiliateCodeObj: null,
      disableGetCode: false,
      cvSubscriptionPlan: "Payment Plans",
      cvSpcmCategoryLabel: "Counseling Category",
      cvSelecteCountryStrategy: "Country Strategy",
      planIdList: [],
      country_strategy_list: [],
      selected_ms_country_strategy: [],
      GideProducts: GideProducts,
      cvCountryOptions: [],
      inviteProductCode: "gpaths"
    }
  },
  components: {
    ValidationProvider
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role. Also allow to open this component in Modal Box and not in a URL as URL is only for GIDE Team
    if (this.userData.user_role != "USERROLE11111" && this.userData.user_role != "USERROLE11118") {
      if (!userPermission(this, this.userData, this.apiName)) {
        this.$router.back()
      }
    }
  },
  mounted () {
    socialvue.index()

    this.inviteProductCode = this.$route.params.productCode ? this.$route.params.productCode : this.propProductCode

    // We are using special plans to track revenue from the usage of referal codes by GIDEPreneur under the revenue src B2G
    if (this.userData.user_role == "USERROLE11116") {
      switch (this.inviteProductCode.toLowerCase()) {
        case "gpaths":
          this.vmProductInviteFormData.subs_plan_id = "GPATHS-GIDEPRENEUR-B2G"
          break
        default:
          console.error("Invalid product code")
      }
    }

    // student data is passed as a prop in spcm fireflies list
    if (this.propStudent) {
      this.vmProductInviteFormData.stu_email = this.propStudent.student_email
    }

    this.updateProduct(this.inviteProductCode)

    this.countryDistinctList()
  },
  methods: {
    updateProduct (inviteProductCode) {
      this.inviteProductCode = inviteProductCode

      if (this.inviteProductCode == GideProducts.COUNSELLING) {
        this.spcm_CountryStrategyList()
      }

      this.getPlanNames(this.inviteProductCode)
    },
    /* eslint-enable */
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmProductInviteFormData) {
          if (!this.vmProductInviteFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error(
          "Exception occurred at validate() and Exception:",
          err.message
        )
        return null
      }
    },
    /**
     * countryDistinctList
     */
    async countryDistinctList () {
      try {
        let matchWithQuery = "distinct country"
        let countryDistinctListResp = await Countries.countryList(this, matchWithQuery)
        if (countryDistinctListResp && !countryDistinctListResp.resp_status) {
          return false
        }
        this.cvCountryOptions = countryDistinctListResp.resp_data.data
      }
      catch (err) {
        console.error("Exception occurred at countryDistinctList() and Exception:", err.message)
      }
    },
    /**
     * generateAffLink
     */
    async generateAffLink () {
      if (this.vmProductInviteFormData.stu_email == "") {
        this.toastTitle = "Invite User"
        this.toastMsg = "User Email Id is Missing"
        this.toastVariant = "danger"
        this.showToast = true
        return
      }

      // Since the button to generate code is clicked set the affiliated flag to true
      this.vmProductInviteFormData.attach_aff_code = true
      /**
       As of now the only product that is affiliated, is GPATHS so hardcoding the product_name to GPATHS, because the module_name in affiliate table needs to be
       the product_name(e.g. GPATHS, SPCMCOUNCEL etc).
       todo:- We have to create a propper system to get other products on GIDE, affiliated
       */
      const getCodeObj = {
        aff_entity_id: this.userData.org_id,
        user_email: this.vmProductInviteFormData.stu_email,
        product_name: this.inviteProductCode
      }
      let affiliateResp = await Affiliates.AffiliateGetCode(this, getCodeObj)

      if (!affiliateResp.resp_status) {
        this.toastTitle = "Invite User"
        this.toastMsg = affiliateResp.resp_msg
        this.toastVariant = "danger"
        this.showToast = true
        return
      }

      this.affiliateCodeObj = affiliateResp.resp_data.data
      this.vmProductInviteFormData.aff_code = this.affiliateCodeObj.aff_code
      this.disableGetCode = true
    },
    /**
     *
     */
    async inviteUser () {
      try {
        let valid = await this.$refs.inviteUserForm.validate()
        if (!valid) {
          return
        }

        if (!this.vmProductInviteFormData.aff_code) {
          this.toastMsg = "Missing Discount/Payment Code"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }

        if (this.affiliateCodeObj) {
          this.vmProductInviteFormData.aff_id = this.affiliateCodeObj.aff_id
          this.vmProductInviteFormData.aff_entity_id = this.propCounsellor.user_id
        }

        this.module_name = this.inviteProductCode
        this.vmProductInviteFormData.product_name = this.inviteProductCode
        this.vmProductInviteFormData.module_name = this.inviteProductCode
        let response = null

        if (this.propCounsellor) {
          this.vmProductInviteFormData.counsellor_id = this.propCounsellor.user_id
        }

        switch (this.module_name) {
          case this.GideProducts.COUNSELLING:
            this.vmProductInviteFormData.ms_country_strategy = this.selected_ms_country_strategy.join(",")
            if (!this.vmProductInviteFormData.stu_class || !this.vmProductInviteFormData.spcm_category || !this.vmProductInviteFormData.ms_country_strategy) {
              this.toastMsg = "Student's class, counseling category and strategy are mandatory"
              this.toastVariant = "danger"
              this.showToast = true
              return
            }
            response = await Spcms.spcmStudentPublicReg(this, this.vmProductInviteFormData)
            break

          case this.GideProducts.GPATHS:
            response = await GPathSessions.gpath_invite(this, this.vmProductInviteFormData)
            break

          case this.GideProducts.TESTSERIES:
            response = await TprepSessions.tprep_invite(this, this.vmProductInviteFormData)
            break

          default:
            console.error(`${this.module_name} is not available to invite`)
            this.toastMsg = `Cannot add a student in this module - ${this.module_name}`
            this.toastVariant = "danger"
            this.showToast = true
            return
        }

        this.toastTitle = "Invite User"
        if (response.resp_code == "ERR_UNUSED_CODE") {
          this.toastVariant = "warning"
        }
        else if (response.resp_code == "ERR_VALIDATION") {
          ApiResponse.responseMessageDisplay(this, response)
        }
        else {
          this.toastVariant = response.resp_status ? "success" : "danger"
        }

        this.toastMsg = response.resp_msg
        this.showToast = true

        if (response.resp_status) {
          this.disableGetCode = false
          this.vmProductInviteFormData.aff_code = null
          this.$emit("emitCloseInviteUser", response)
        }
      }
      catch (err) {
        console.error("Exception in Invite: ", err)
      }
    },
    /**
     * spcm_CountryStrategyList
    */
    async spcm_CountryStrategyList () {
      try {
        let spcmCountryStrategyListResp = await spcmMilestones.spcm_CountryStrategyList(this, this.whereFilter)
        if (spcmCountryStrategyListResp.resp_status) {
          this.country_strategy_list = spcmCountryStrategyListResp.resp_data.data.map(e => e.ms_country_strategy)
        }
        else {
          this.showLoadMoreBtn = false
          this.toastMsg = spcmCountryStrategyListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at spcm_CountryStrategyObjList() and Exception:", err)
      }
    },
    /**
     * initFormData
     */
    initFormData () {
      return {
        student_name: "",
        stu_first_name: "",
        stu_last_name: "",
        stu_email: "",
        user_type: "Student",
        product_name: this.inviteProductCode,
        aff_code: ""
      }
    },
    /**
     * getPlanNames
     */
    async getPlanNames (inviteProductCode) {
      try {
        const subsPlanListResp = await SubscriptionPlans.subscriptionPlanList(this, { subs_plan_name: inviteProductCode })
        if (subsPlanListResp.resp_status) {
          this.planIdList = subsPlanListResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Error in getPlanNames ", err)
      }
    }
  }
}
</script>
<style>
@media (max-width: 991px) {
  label{
    font-size:13px;
  }
}
</style>
